html {
  --house-side-piece-width: 5vw;
}

.house {
  position: relative;
  /* background: url(../../public/images/roomCenter.webp) repeat; */
  background: url(../../public/images/room-center-new.jpeg) repeat-x;
  background-size: 200px 100%;
  margin-left: var(--house-side-piece-width);
  width: calc(100% - var(--house-side-piece-width) * 2);
  padding-bottom: 50%;
}

.house::before, .house::after {
  content: '';
  position: absolute;
  top: 0;
  display: block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: var(--house-side-piece-width);
  height: 100%;
}

.house::before {
  left: calc(var(--house-side-piece-width) * -1);
  /* background-image: url(../../public/images/roomLeft.webp);*/
  background-image: url(../../public/images/room-left-new.jpeg);
}

.house::after {
  right: calc(var(--house-side-piece-width) * -1);
  /* background-image: url(../../public/images/roomRight.webp); */
  background-image: url(../../public/images/room-right-new.jpeg);
}