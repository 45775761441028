.tenant {
  position: absolute;
  transition: all ease 20s;
}

.tenant-image {
  transition: transform ease 2s;
  transform: scale(0.5) translateY(50%);
}

.tenant-image-rent-ready {
  transform: scale(1) translateY(0%);
}

.tenant-status {
  top: 0rem;
}

.tenant-status-rent-ready {
  top: -2.5rem;
  transform: scale(1) translateY(0%);
}
/*
.tenant-image {
  animation-name: tenant-shrink;
  animation-duration: 3s;
  animation-fill-mode: both;
  animation-delay: 8s;
}

@keyframes tenant-shrink {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.5) translateY(50%);
  }
}
*/