*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: Japandi;
  font-weight: lighter;
  src: url(../../public/fonts/Japandi%20Light.otf);
}

@font-face {
  font-family: Japandi;
  font-weight: normal;
  src: url(../../public/fonts/Japandi%20Regular.otf);
}

@font-face {
  font-family: Japandi;
  font-weight: bold;
  src: url(../../public/fonts/Japandi%20Bold.otf);
}

/*
Soft Blue: #AED9E0
Mint Green: #B2D8B2
Peach or Coral: #FFB6A0
Light Gray: #D3D3D3
*/

button {
  appearance: none;
  background: transparent;
  border: none;
  outline: none;
}

.form-button {
  color: #000;
  border: double #000 0.2rem;
  border-radius: 0.25rem;
  padding: 0.25rem 2rem;
  font-size: 1rem;
}

html,
body {
  background-color: #000;
}

button {
  cursor: pointer;
}

.base-screen {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 800px;
}

/*
@media screen and (orientation: portrait) {
  .base-screen {
    transform: rotate(90deg) scale(1.5);
  }
}
*/

.row {
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 1.05rem;
}

h1 {
  font-family: Japandi;
}

h2,
h3,
button {
  font-family: Helvetica Neue;
}

.typography-normal, .typography-small {
  max-width: 25rem;
}

.typography-normal {
  font-family: Helvetica Neue;
}

.typography-small {
  font-size: 0.8rem;
}


.focal-point {
  position: relative;
}

.focal-point::after {
  content: "";
  display: block;
  background: url(../../public/images/pointer.webp) no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: -30px;
  left: calc(50% - 15px);

  animation: focal-pointer ease-in-out 1s infinite;
}

@keyframes focal-pointer {
  0% {
    translate: 0 0%;
  }
  50% {
    translate: 0 25%;
    transform: scale(1.25);
  }
  100% {
    translate: 0 0%;
  }
}

p {
  font-family: Helvetica; 
}